import { Teaser } from "@/components/molecules/Teaser";
import { cn } from "@/helpers/className";

export const CustomTeaserParagraph = ({ data, className }) => {
  return (
    <div className={cn(className, "paragraph")}>
      <Teaser
        title={data.title}
        media={data.paragraphCustomTeaserMedia?.imageMediaImage ?? undefined}
        description={data.paragraphCustomTeaserText}
        link={data.paragraphCustomTeaserLink}
      />
    </div>
  );
};
