import {cn} from "@/helpers/className";
import Icon from "@/components/atoms/Icon";
import NewsletterForm from "@/components/forms/NewsletterForm";
import {AllowedLanguages} from "@/types/generic";

interface NewsletterProps {
  className?: string;
  locale: AllowedLanguages;
  location?: string;
  searchQuery?: string;
  title: string;
}

export const Newsletter = ({className, title, locale, location, searchQuery}: NewsletterProps) => {
  return (
    <div
      className={cn('relative', className)}>
      <div
        className="rounded-full bg-yellow w-20 aspect-square grid place-items-center absolute top-0 -translate-y-1/2">
        <Icon name="paperplane" className="text-black w-8 h-8"/>
      </div>
      <h4 className="text-white lg:!mb-10" dangerouslySetInnerHTML={{__html: title}}></h4>
      <NewsletterForm locale={locale} location={location} searchQuery={searchQuery}/>
    </div>
  );
};

export default Newsletter;