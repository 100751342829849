'use client';

import TextInput from "@/components/forms/controls/TextInput";
import {useTranslation} from "@/i18n/client";
import Form from "@/components/forms/Form";
import {z} from "zod";
import {useZodForm} from "@/hooks/useZodForm";

export const FaqSearchForm = ({
      locale,
      value,
  }) => {
  const { t } = useTranslation(locale);

  const schema = z.object({
    search: z.string(),
  });

  const form = useZodForm({ schema });
  const searchResultsPage = `/${locale}/faq/search`;

  return (
    <>
      <Form
        action={searchResultsPage}
        method={'GET'}
        form={form}
        noValidate={true}
        className="flex justify-stretch lg:justify-center mb-10 lg:mb-16"
      >
        <TextInput
          label={t('faq_overview.search.placeholder')}
          placeholder={t('faq_overview.search.placeholder')}
          hiddenLabel={true}
          className="w-full lg:w-[600px]"
          inputClassname="form-input-xl pl-16 focus:shadow-xl"
          icon="magnify"
          name={'search'}
          defaultValue={value}
        />
      </Form>
    </>
  );
};

export default FaqSearchForm;
