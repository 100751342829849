import { create } from "zustand";

import { fallbackLng } from "@/i18n/settings";
import type { AllowedLanguages } from "@/types/generic";

interface UsePageLocale {
  pageLocale: AllowedLanguages;
  setPageLocale: (locale: AllowedLanguages) => void;
}

export const usePageLocale = create<UsePageLocale>((set) => ({
  pageLocale: fallbackLng,
  setPageLocale: (locale) => {
    set(() => ({ pageLocale: locale }));
  },
}));
