'use client';

import {cn} from "@/helpers/className";
import {HtmlParagraphFieldsFragment} from "@/graphql/sdk/__generated__";
import InnerHTML from 'dangerously-set-html-content'

export const HtmlParagraph = ({data, className}: { className?: string, data: HtmlParagraphFieldsFragment }) => {

  return (
    <div className={cn("paragraph--code container", className)}>
      <InnerHTML html={data.code}/>
    </div>
  );
};

export default HtmlParagraph;
