"use client";

import { useMemo } from "react";

import {
  StatisticItemParagraphFieldsFragment,
  StatisticParagraphFieldsFragment,
} from "@/graphql/sdk/__generated__";
import { cn } from "@/helpers/className";

interface StatisticParagraphProps {
  className?: string;
  data: StatisticParagraphFieldsFragment;
}

export const StatisticParagraph = ({
  data,
  className,
}: StatisticParagraphProps) => {
  const bestColumnCount = useMemo(() => {
    const defaultColumnCount = 3;
    const itemCount = data.statisticItems?.length ?? 0;
    if (itemCount < 3) {
      return itemCount || 1;
    }

    // On desktop, we want to display 3 or 4 items per row.
    // If the number of items is less than 3, we display them in column = itemsCount.

    // most popular cases:
    // - 3 items
    // - 4 items

    if (itemCount % 3 === 0) {
      return 3;
    }

    if (itemCount % 4 === 0) {
      return 4;
    }

    return defaultColumnCount;
  }, []);

  return (
    <div className={cn(className, "paragraph--statistic container")}>
      <div className="grid grid-cols-12">
        <div
          className={cn(
            "sm:grid-flow-cols col-span-12 grid grid-flow-row gap-8 sm:gap-12 xl:col-span-8 xl:col-start-3",
            bestColumnCount === 1 && "sm:grid-cols-1",
            bestColumnCount === 2 && "sm:grid-cols-2",
            bestColumnCount === 3 && "sm:grid-cols-3",
            bestColumnCount === 4 && "sm:grid-cols-4",
          )}
        >
          {data.statisticItems?.map((item) => (
            <StatisticItem key={item.id} data={item} />
          ))}
        </div>
      </div>
    </div>
  );
};

interface StatisticItemProps {
  data: StatisticItemParagraphFieldsFragment;
}

const StatisticItem = ({ data }: StatisticItemProps) => {
  return (
    <div className="flex flex-col">
      <h2 className="!mb-3 text-black-400">
        {data.statisticItemAmount}
        <span className="text-orange">+</span>
      </h2>
      <h5 className="!mb-6 text-black-400">{data.statisticItemEntity}</h5>
      <div
        className="prose text-black-400"
        dangerouslySetInnerHTML={{
          __html: data.statisticItemDescription.processed,
        }}
      />
    </div>
  );
};
