import { match } from "ts-pattern";

import { Alert } from "@/components/molecules/Alert";
import { CustomTeaserParagraph } from "@/components/paragraphs/child-paragraphs/CustomTeaserParagraph";
import { ReferenceTeaserParagraph } from "@/components/paragraphs/child-paragraphs/ReferenceTeaserParagraph";
import { ParagraphFieldsFragment } from "@/graphql/sdk/__generated__";

interface ChildParagraphProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  type: ParagraphFieldsFragment["__typename"];
}

const ChildParagraph = ({ type, data }: ChildParagraphProps) => {
  // Ignore empty or unknown paragraphs
  if (!type || !data) {
    return <></>;
  }

  const Component = match(type)
    .with("ParagraphCustomTeaser", () => CustomTeaserParagraph)
    .with("ParagraphReferenceTeaser", () => ReferenceTeaserParagraph)
    .otherwise(() => null);

  if (!Component) {
    return (
      <div className="container">
        <Alert
          type={"negative"}
          title={"Paragraph not found"}
          className="mx-auto my-8 max-w-4xl"
          message={`Paragraph of type <strong>${type}</strong> could not be rendered because it does not exist in the frontend application.`}
        />
      </div>
    );
  }

  return <Component data={data} />;
};

export { ChildParagraph };
