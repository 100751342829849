'use client';

import {cn} from "@/helpers/className";
import {TestimonialsParagraphFieldsFragment} from "@/graphql/sdk/__generated__";
import Testimonial from "@/components/molecules/Testimonial";
import {Splide, SplideSlide} from "@splidejs/react-splide";

import "@splidejs/splide/dist/css/splide-core.min.css";

export const TestimonialsParagraph = ({data, className}: {
  className?: string,
  data: TestimonialsParagraphFieldsFragment
}) => {
  const bgColor = data.entity_type_behaviors.background_color || "white";
  const mapBgColor = {
    green: "bg-green",
    yellow: "bg-yellow-600",
    orange: "bg-orange",
    white: "bg-white",
    lightYellow: "bg-yellow",
    lightMint: "bg-green-100",
    grey: "bg-grey",
    stroke: 'bg-border',
    mint: "bg-green-300",
    darkGreen: "bg-green-600",
  };
  const textLight = ['green', 'orange'].includes(bgColor);

  return (
    <div className={cn("paragraph--quote container", className)}>
      <div className={cn(
        'grid grid-cols-12',
        bgColor && `py-10 lg:py-24 px-6 ${mapBgColor[bgColor]}`,
      )}
      >
        <div
          className={cn(
            'col-span-12 md:col-span-10 md:col-start-2 lg:col-span-8 lg:col-start-3'
          )}>
          {data.title && (
            <h2 className={cn('h3 text-center !mb-10', textLight && 'text-white')}>{data.title}</h2>
          )}
          <Splide
            options={{
              arrows: false,
              gap: "1rem",
              pagination: true,
              perPage: 1,
              classes: {
                pagination: "splide__pagination gap-2 !mt-8",
                page: "splide__pagination__page w-2 h-2 rounded-full bg-border",
              },
              autoplay: true,
              autoplaySpeed: 5000,
              rewind: true
            }}
            className="items-center"
          >
            {data.testimonials.map((testimonial) => (
              <SplideSlide key={testimonial.id} className="flex items-center justify-center">
                <Testimonial data={testimonial} textLight={textLight}/>
              </SplideSlide>
            ))}
          </Splide>
        </div>
      </div>
    </div>
  );
};

export default TestimonialsParagraph;
