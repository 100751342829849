import Link from "next/link";

import DrupalImage from "@/components/atoms/DrupalImage";
import {
  ImageFieldsFragment,
  ImageStyleAvailable,
} from "@/graphql/sdk/__generated__";
import { cn } from "@/helpers/className";
import { getVariation } from "@/helpers/image";
import logo from "@/assets/images/logo-vivaldis.svg";
import Image from "next/image";

interface TeaserProps {
  description?: string;
  media?: ImageFieldsFragment;
  link?: {
    internal: boolean;
    title: string;
    url: string;
  };
  title: string;
}

export const Teaser = ({ title, description, media, link }: TeaserProps) => {
  const image = media?.variations
    ? getVariation(media.variations, ImageStyleAvailable.W950Teaser)
    : null;
  const mobileImage = media?.variations
    ? getVariation(media.variations, ImageStyleAvailable.W1440TeaserMobile)
    : null;

  return (
    <div
      className={cn(
        "default-teaser relative flex flex-col items-stretch rounded-lg border bg-white transition-all hover:border-yellow-600 hover:shadow-lg",
      )}
    >
      <div className="relative w-full aspect-video overflow-hidden rounded-t-lg bg-yellow flex shrink-0 items-center justify-center">
        {image ? (
          <>
            <DrupalImage
              url={mobileImage.url}
              alt={media.alt}
              width={mobileImage.width}
              height={mobileImage.height}
              className="w-full rounded-2xl transition-transform duration-300 xl:hidden xl:group-hover:scale-105"
            />
            <DrupalImage
              url={image.url}
              alt={media.alt}
              width={image.width}
              height={image.height}
              sizes="33vw"
              className="hidden w-full rounded-2xl transition-transform duration-300 xl:block xl:group-hover:scale-105"
            />
          </>
        ) : (
            <Image
                src={logo}
                width={80}
                height={42}
                alt="Vivaldis Interim"
                className="w-[160px]"
            />
        )}
      </div>
      <div className="flex flex-1">
        <div className="p-6 lg:p-8 w-full h-full flex flex-col">
          {title && (
              <h4 className={cn("h5 mt-0 !mb-1 break-words")}>
                {title}
              </h4>
          )}

          {description && (
              <div
                  className="prose line-clamp-2 max-w-none text-sm lg:text-base max-w-full mt-2 mb-4"
                  dangerouslySetInnerHTML={{ __html: description.processed }}
              />
          )}
          {link && (
            <Link
                href={link.url}
                className="stretched-link mt-auto pt-4 mr-auto"
            >
              <span className="link text-sm no-underline pb-1">{link.title}</span>
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};
