import {ImageStyleAvailable, ParagraphTestimonial} from "@/graphql/sdk/__generated__";
import DrupalImage from "@/components/atoms/DrupalImage";
import {cn} from "@/helpers/className";
import {getVariation} from "@/helpers/image";

interface TestimonialProps {
  className?: string;
  data: ParagraphTestimonial;
  textLight?: boolean;
}

export const Testimonial = ({data, className, textLight}: TestimonialProps) => {
  let image = null;

  if(data.authorImage?.imageMediaImage?.variations) {
    image = getVariation(data.authorImage?.imageMediaImage?.variations, ImageStyleAvailable.Avatar);
  }

  return (
    <div className={cn('text-center flex flex-col items-center', className)}>
      <div
        className={cn(
          'text-lg text-black-400 prose max-w-full mb-5',
          textLight && '!text-white prose-invert'
        )}
        dangerouslySetInnerHTML={{__html: data.text.processed}}
      ></div>

      {data.authorImage && image && (
        <DrupalImage
          url={image.url}
          alt={data.authorImage.imageMediaImage.alt ?? ''}
          width={image.width}
          height={image.height}
          className={"mx-auto w-20 rounded-full mb-2 mt-2"}
          priority
        />
      )}
      {data.author && (
        <strong className={cn('block font-display mb-1', textLight && 'text-white')}>{data.author}</strong>
      )}
      {data.functionTitle && (
        <div className={cn('text-sm text-black-300', textLight && 'text-white/80')}>{data.functionTitle}</div>
      )}
    </div>
  );
};

export default Testimonial;