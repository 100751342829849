'use client';

import { TwoTextColumnsParagraphFieldsFragment, } from "@/graphql/sdk/__generated__"
import { cn } from "@/helpers/className";
import Button from "@/components/atoms/Button";
import Link from "next/link";

interface TextTextParagraphProps {
  className?: string;
  data: TwoTextColumnsParagraphFieldsFragment;
}

export const ParagraphTwoTextColumns = ({ data, className }: TextTextParagraphProps) => {
  return (
    <div className={cn(className, "paragraph--two-text-columns container")}>
      <div className="grid grid-cols-12">
        <div className='col-span-12 xl:col-span-8 xl:col-start-3'>
          {data.title ?
            <div className="mb-8 lg:mb-12">
              <h2>{data.title}</h2>
            </div>
            : null
          }
          <div className="flex flex-col gap-y-12 lg:flex-row lg:gap-x-12">
            <div className="flex flex-col basis-1/2">
              <div
                className="prose"
                dangerouslySetInnerHTML={{
                  __html: data.textForColumn1.processed as string,
                }}
              />

              {data.linksForColumn1 && (
                <div className="flex flex-wrap items-center gap-6 lg:gap-8 mt-8 lg:mt-12">
                  {data.linksForColumn1.map((link, index) => (
                    (index === 0) ? (
                      <Button
                        as='link'
                        key={link.url}
                        href={link.url}
                        arrow={data.linksForColumn1.length === 1}
                        className="btn-lg"
                      >
                        {link.title}
                      </Button>
                    ) : (
                      <Link
                        key={link.url}
                        href={link.url}
                        className="link"
                      >
                        {link.title}
                      </Link>
                    )
                  ))}
                </div>
              )}
            </div>
            <div className="flex flex-col basis-1/2">
              <div
                className="prose"
                dangerouslySetInnerHTML={{
                  __html: data.textForColumn2.processed as string,
                }}
              />

              {data.linksForColumn2 && (
                <div className="flex flex-wrap items-center gap-6 lg:gap-8 mt-8 lg:mt-12">
                  {data.linksForColumn2.map((link, index) => (
                    (index === 0) ? (
                      <Button
                        as='link'
                        key={link.url}
                        href={link.url}
                        arrow={data.linksForColumn2.length === 1}
                        className="btn-lg"
                      >
                        {link.title}
                      </Button>
                    ) : (
                      <Link
                        key={link.url}
                        href={link.url}
                        className="link"
                      >
                        {link.title}
                      </Link>
                    )
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
};
