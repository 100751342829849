"use client";

import Image from "next/image";

interface DrupalImageProps
  extends Omit<React.ComponentProps<typeof Image>, "loader" | "src"> {
  className?: string;
  height: number;
  url: string;
  width: number;
}

/**
 * Drupal Image
 * Image component from next/image with custom loader
 */
const DrupalImage = ({ ...props }: DrupalImageProps) => {
  const { url, ...rest } = props;

  return (
    <Image
      {...rest}
      src={url}
      alt={props.alt}
      width={props.width}
      height={props.height}
      className={props.className}
    />
  );
};

export default DrupalImage;
