'use client'

import DrupalImage from "@/components/atoms/DrupalImage"
import {
  ImageStyleAvailable, LinkFieldsFragment,
  Maybe, MediaImageFieldsFragment,
  ThreeImagesParagraphFieldsFragment,
} from "@/graphql/sdk/__generated__"
import { cn } from "@/helpers/className"
import { getVariation } from "@/helpers/image"
import Button from "@/components/atoms/Button"
import Link from "next/link"

interface ThreeImagesParagraphProps {
  className?: string;
  data: ThreeImagesParagraphFieldsFragment;
}

export const ThreeImagesParagraph = ({data, className}: ThreeImagesParagraphProps) => {
  return (
    <div className={cn(className, "paragraph--three-images container")}>
      <div className="flex flex-col gap-12">
        {data.title && <h2 className="text-center">{data.title}</h2>}
        <div className="flex flex-col sm:flex-row gap-8 sm:gap-12">
          <div className="flex flex-col basis-1/3">
            <Image image={data.image1ForThreeImages} link={data.linkForImageInBlock1}/>
            {data.linksForBlock1 && (
              <div className="flex flex-col items-center gap-6 mt-8 lg:mt-12">
                {data.linksForBlock1.map((link, index) => (
                  (index === 0) ? (
                    <Button
                      as='link'
                      key={link.url}
                      href={link.url}
                      arrow={data.linksForBlock1.length === 1}
                      className="btn-lg"
                    >
                      {link.title}
                    </Button>
                  ) : (
                    <Link
                      key={link.url}
                      href={link.url}
                      className="link"
                    >
                      {link.title}
                    </Link>
                  )
                ))}
              </div>
            )}
          </div>
          <div className="flex flex-col basis-1/3">
            <Image image={data.image2ForThreeImages} link={data.linkForImageInBlock2}/>
            {data.linksForBlock2 && (
              <div className="flex flex-col items-center gap-6 mt-8 lg:mt-12">
                {data.linksForBlock2.map((link, index) => (
                  (index === 0) ? (
                    <Button
                      as='link'
                      key={link.url}
                      href={link.url}
                      arrow={data.linksForBlock2.length === 1}
                      className="btn-lg"
                    >
                      {link.title}
                    </Button>
                  ) : (
                    <Link
                      key={link.url}
                      href={link.url}
                      className="link"
                    >
                      {link.title}
                    </Link>
                  )
                ))}
              </div>
            )}
          </div>
          <div className="flex flex-col basis-1/3">
            <Image image={data.image3ForThreeImages} link={data.linkForImageInBlock3}/>
            {data.linksForBlock3 && (
              <div className="flex flex-col items-center gap-6 mt-8 lg:mt-12">
                {data.linksForBlock3.map((link, index) => (
                  (index === 0) ? (
                    <Button
                      as='link'
                      key={link.url}
                      href={link.url}
                      arrow={data.linksForBlock3.length === 1}
                      className="btn-lg"
                    >
                      {link.title}
                    </Button>
                  ) : (
                    <Link
                      key={link.url}
                      href={link.url}
                      className="link"
                    >
                      {link.title}
                    </Link>
                  )
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}


interface ImageProps {
  image: MediaImageFieldsFragment
  link: Maybe<LinkFieldsFragment>
}

const Image = ({image, link}: ImageProps) => {
  if (!image?.imageMediaImage?.variations) {
    return null
  }

  const imageVariation = getVariation(image.imageMediaImage.variations, ImageStyleAvailable.W2000)

  if (!imageVariation) {
    return null
  }

  const imageElement = (
    <DrupalImage
      url={imageVariation.url}
      alt={image.imageMediaImage?.alt ?? ''}
      width={imageVariation.width}
      height={imageVariation.height}
      className={"w-full rounded-3xl"}
    />
  )

  const linkURL = link?.url
  if (linkURL) {
    return (
      <Link href={linkURL}>
        {imageElement}
      </Link>
    )
  }

  return (
    <>
      {imageElement}
    </>
  )
}
