import Icon, { Icons } from "@/components/atoms/Icon";
import { cn } from "@/helpers/className";

interface BadgeProps {
  children: React.ReactNode;
  className?: string;
  color: string;
  icon: Icons;
  iconClassName?: string;
  small?: boolean;
}

const Tag = ({
  children,
  className,
  icon,
  color,
  iconClassName,
  small = false,
}: BadgeProps) => {
  let bgColorClass = "bg-grey-100";
  if (color === "white") {
    bgColorClass = "bg-white";
  }
  return (
    <span
        className={cn(
            "inline-flex h-8 items-center gap-2 whitespace-nowrap rounded p-2 text-sm text-black-400 truncate",
            bgColorClass,
            !small && "lg:h-10 lg:px-3 lg:py-4",
            className,
        )}
    >
      {icon && (
        <Icon
          name={icon}
          className={cn("text-medium h-4 w-4 text-black-300", iconClassName)}
        />
      )}
      <span className={'truncate'}>
        {children}
      </span>
    </span>
  );
};

export default Tag;
