import Link from "next/link";

interface LinkWithSuffixProps {
  link: {
    suffix?: string;
    title: string;
    url: string;
  };
}

export const LinkWithSuffix = ({link}: LinkWithSuffixProps) => {
  return (
    <>
      <Link href={link.url} className="text-gray-600 hover:text-black transition-colors flex-grow" prefetch={false}>
        {link.title}
      </Link>
      {link.suffix && (
        <span className="rounded-xl bg-yellow px-2 py-1 text-xs text-black">
          {link.suffix}
        </span>
      )}
    </>
  );
};

export default LinkWithSuffix;