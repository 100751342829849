'use client';;

import {cn} from "@/helpers/className";
import {QuoteParagraphFieldsFragment} from "@/graphql/sdk/__generated__";
import React, { useRef } from 'react';
import { useInViewport } from 'react-in-viewport';
export const QuoteParagraph = ({data, className}: { className?: string, data: QuoteParagraphFieldsFragment }) => {
  const blockQuoteRef = useRef();
  const {
    inViewport
  } = useInViewport(
    blockQuoteRef,
    {},
    { disconnectOnLeave: false },
  );

  return (
    <div className={cn("paragraph--quote container", className)}>
      <div className="grid grid-cols-12">
        <div className="col-span-12 md:col-span-10 md:col-start-2 lg:col-span-8 lg:col-start-3 2xl:col-span-6 2xl:col-start-4 flex flex-col justify-center">
          <div className="inline-flex flex-col mx-auto">
            <blockquote
              className={cn("prose max-w-full inline-flex flex-col", inViewport && 'in-viewport')}
              dangerouslySetInnerHTML={{
                __html: data.text.processed,
              }}
              ref={blockQuoteRef}
            ></blockquote>
            {data.author && (
              <p className="blockquote__author">{data.author}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuoteParagraph;