import {Teaser} from "@/components/molecules/Teaser";
import {ParagraphReferenceTeaser} from "@/graphql/sdk/__generated__";
import {cn} from "@/helpers/className";
import {useTranslation} from "@/i18n/client";
import {fallbackLng} from "@/i18n/settings";
import useSWR from "swr";
import {getStandardPageTeaser} from "@/graphql";
import {Loader} from "@/components/atoms/Loader";

interface ReferenceTeaserParagraphProps {
  className?: string;
  data: ParagraphReferenceTeaser;
}

export const ReferenceTeaserParagraph = (
  {
    data,
    className,
  }: ReferenceTeaserParagraphProps) => {

  async function fetcher(nodePageId: string) {
    return await getStandardPageTeaser({
      nodePageId,
    });
  }

  const {data: pageData, error, isLoading} = useSWR(data.contentRef.id, fetcher);

  const page = pageData?.nodePage ?? null;

  const {t} = useTranslation(page?.langcode?.id ?? fallbackLng);

  if (error || (!isLoading && !page)) return <></>

  return (
    <div className={cn(className, "paragraph")}>
      {isLoading ? (
        <Loader size="small"/>
      ) : (
        <Teaser
          title={page.title}
          media={page.teaserMedia?.imageMediaImage ?? undefined}
          description={page.intro?.processed}
          link={{
            internal: true,
            title: t("teaser.read_more"),
            url: page.path,
          }}
        />
      )}
    </div>
  );
};
