"use client";

import { useMemo } from "react";
import Link from "next/link";

import Button from "@/components/atoms/Button";
import DrupalImage from "@/components/atoms/DrupalImage";
import {
  ImageStyleAvailable,
  TwoColumnBlockParagraphFieldsFragment,
} from "@/graphql/sdk/__generated__";
import { cn } from "@/helpers/className";
import { getVariation } from "@/helpers/image";

interface TwoColumnBlockParagraphProps {
  className?: string;
  data: TwoColumnBlockParagraphFieldsFragment;
}

export const ParagraphTwoColumnBlock = ({
  data,
  className,
}: TwoColumnBlockParagraphProps) => {
  const imageForBlock1 = useMemo(() => {
    if (
      !data.paragraphTwoColumnBlockImageForBlock1.imageMediaImage?.variations
    ) {
      return null;
    }
    return getVariation(
      data.paragraphTwoColumnBlockImageForBlock1.imageMediaImage.variations,
      ImageStyleAvailable.W2000,
    );
  }, [data.paragraphTwoColumnBlockImageForBlock1?.imageMediaImage?.variations]);

  const imageForBlock2 = useMemo(() => {
    if (
      !data.paragraphTwoColumnBlockImageForBlock2.imageMediaImage?.variations
    ) {
      return null;
    }
    return getVariation(
      data.paragraphTwoColumnBlockImageForBlock2.imageMediaImage.variations,
      ImageStyleAvailable.W2000,
    );
  }, [data.paragraphTwoColumnBlockImageForBlock2?.imageMediaImage?.variations]);

  return (
    <div className={cn(className, "paragraph--two-column-block container")}>
      <div className="grid grid-cols-12">
        <div className="col-span-12 xl:col-span-8 xl:col-start-3">
          {data.label ? (
            <h5 className="caption text-center !text-sm text-orange !mb-12">
              {data.label}
            </h5>
          ) : null}

          {data.title ? (
            <h2 className="text-center !mb-12">{data.title}</h2>
          ) : null}

          <div className="flex flex-col gap-y-12 lg:flex-row lg:gap-x-8">
            <div className="flex basis-1/2 flex-col gap-y-6 lg:gap-y-8">
              {imageForBlock1 && (
                <DrupalImage
                  url={imageForBlock1.url}
                  alt={
                    data.paragraphTwoColumnBlockImageForBlock1.imageMediaImage
                      ?.alt ?? ""
                  }
                  width={imageForBlock1.width}
                  height={imageForBlock1.height}
                  className={"w-full rounded-2xl"}
                />
              )}

              {data.labelForBlock1 && (
                <div className="h5 caption !mb-0 !text-sm text-orange">
                  {data.labelForBlock1}
                </div>
              )}
              {data.paragraphTwoColumnBlockTitleForBlock1 && (
                <h4 className="!mb-0">
                  {data.paragraphTwoColumnBlockTitleForBlock1}
                </h4>
              )}

              <div
                className="prose"
                dangerouslySetInnerHTML={{
                  __html: data.paragraphTwoColumnBlockTextForBlock1
                    .processed as string,
                }}
              />

              {data.linksForBlock1 && (
                <div className="flex flex-wrap items-center gap-6 lg:gap-8">
                  {data.linksForBlock1.map((link, index) =>
                    index === 0 ? (
                      <Button
                        as="link"
                        key={link.url}
                        href={link.url}
                        arrow={data.linksForBlock1.length === 1}
                        className="btn-lg"
                      >
                        {link.title}
                      </Button>
                    ) : (
                      <Link key={link.url} href={link.url} className="link">
                        {link.title}
                      </Link>
                    ),
                  )}
                </div>
              )}
            </div>
            <div className="flex basis-1/2 flex-col gap-y-6 lg:gap-y-8">
              {imageForBlock2 && (
                <DrupalImage
                  url={imageForBlock2.url}
                  alt={
                    data.paragraphTwoColumnBlockImageForBlock2.imageMediaImage
                      ?.alt ?? ""
                  }
                  width={imageForBlock2.width}
                  height={imageForBlock2.height}
                  className={"w-full rounded-2xl"}
                />
              )}

              {data.labelForBlock2 && (
                <div className="h5 caption !mb-0 !text-sm text-orange">
                  {data.labelForBlock2}
                </div>
              )}
              {data.paragraphTwoColumnBlockTitleForBlock2 && (
                <h4 className="!mb-0">
                  {data.paragraphTwoColumnBlockTitleForBlock2}
                </h4>
              )}

              <div
                className="prose"
                dangerouslySetInnerHTML={{
                  __html: data.paragraphTwoColumnBlockTextForBlock2
                    .processed as string,
                }}
              />

              {data.linksForBlock2 && (
                <div className="flex flex-wrap items-center gap-6 lg:gap-8">
                  {data.linksForBlock2.map((link, index) =>
                    index === 0 ? (
                      <Button
                        as="link"
                        key={link.url}
                        href={link.url}
                        arrow={data.linksForBlock2.length === 1}
                        className="btn-lg"
                      >
                        {link.title}
                      </Button>
                    ) : (
                      <Link key={link.url} href={link.url} className="link">
                        {link.title}
                      </Link>
                    ),
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
