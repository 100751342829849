'use client';

import {cn} from "@/helpers/className";
import Newsletter from "@/components/molecules/Newsletter";
import {useTranslation} from "@/i18n/client";
import {usePageLocale} from "@/store/pageLocale";

export const NewsletterFormParagraph = ({data, className}) => {
  const {pageLocale} = usePageLocale();
  const {t} = useTranslation(pageLocale);

  return (
    <div className={cn(className, "paragraph--teaser lg:container")}>
      <div className="grid grid-cols-12">
        <div className="col-span-12 lg:col-span-10 lg:col-start-2 xl:col-span-8 xl:col-start-3">
          <Newsletter
            locale={pageLocale}
            title={t('newsletter.title')}
            className="bg-green text-white rounded-2xl p-6 pt-16 md:p-10 md:pt-16 lg:px-16 lg:py-14 lg:pt-20"
          />
        </div>
      </div>

    </div>
  );
};

export default NewsletterFormParagraph;
